<template>
  <div class="main">
    <van-collapse v-model="activeNames" style="margin-bottom:10px">
      <van-collapse-item name="1">
        <template #title>
          <div>搜索条件</div>
        </template>
        <div class="quickButton">
          <van-row>
            <van-col span="6">快捷选择：</van-col>
            <van-col span="18" class="quickAction">
              <div
                :class="{ quickActionActive: dateactive == 'date' }"
                @click="dateactive = 'date'"
              >
                一天
              </div>
              <div
                :class="{ quickActionActive: dateactive == 'week' }"
                @click="dateactive = 'week'"
              >
                一周
              </div>
              <div
                :class="{ quickActionActive: dateactive == 'month' }"
                @click="dateactive = 'month'"
              >
                一月
              </div>
            </van-col>
          </van-row>
        </div>
        <van-list style="margin-bottom:10px">
          <van-cell>
            <van-row
              justify="space-between"
              @click="state.showStartCalendar = true"
            >
              <van-col span="6">开始日期</van-col>
              <van-col span="18">{{ state.start }}</van-col>
            </van-row>
          </van-cell>
          <van-cell>
            <van-row
              justify="space-between"
              @click="state.showEndCalendar = true"
            >
              <van-col span="6">结束日期</van-col>
              <van-col span="18">{{ state.end }}</van-col>
            </van-row>
          </van-cell>
          <van-cell>
            <van-row justify="space-between" @click="state.showPicker = true">
              <van-col span="6">数据类型</van-col>
              <van-col span="18">{{ state.datatypename }}</van-col>
            </van-row>
          </van-cell>
        </van-list>
        <div class="factorlist">
          <div
            class="factorButton"
            v-for="items in factorlist"
            :key="items.tempfactor_id"
            :class="{
              factorActive:
                (',' + query.factor_id + ',').indexOf(
                  ',' + items.tempfactor_id + ','
                ) != -1
            }"
            @click="deloradd(items.tempfactor_id)"
          >
            {{ items.factor_id }}
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
    <!-- 柱状图 -->
    <div class="echartBox">
      <div class="echartDiv" ref="barChart" id="barChart"></div>
    </div>
    <!-- 折线图 -->
    <div class="echartBox">
      <div class="echartDiv" ref="lineChart" id="lineChart"></div>
    </div>
    <!-- 日期控件 -->
    <van-popup v-model:show="state.showStartCalendar" position="bottom">
      <van-datetime-picker
        type="datetime"
        @confirm="onStartConfirm"
        @cancel="state.showStartCalendar = false"
        :min-date="state.mindate"
        :max-date="new Date(state.end)"
      />
    </van-popup>
    <van-popup v-model:show="state.showEndCalendar" position="bottom">
      <van-datetime-picker
        type="datetime"
        @confirm="onEndConfirm"
        @cancel="state.showEndCalendar = false"
        :min-date="new Date(state.start)"
      />
    </van-popup>
    <!-- <van-datetime-picker type="datetime" v-model:show="state.showStartCalendar" @confirm="onStartConfirm" :min-date="state.mindate" :max-date="new Date(state.end)"/> -->
    <!-- <van-datetime-picker type="datetime" v-model:show="state.showEndCalendar" @confirm="onEndConfirm" :min-date="new Date(state.start)"/> -->
    <!-- 选择器 -->
    <van-popup v-model:show="state.showPicker" position="bottom">
      <van-picker
        :columns="datatypeList"
        @confirm="onDataTypeConfirm"
        @cancel="state.showPicker = false"
        :columns-field-names="customFieldName"
      />
    </van-popup>
  </div>
</template>
<script>
import { ref, reactive, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import $echarts from "echarts";
import Http from "../../../api/http";
export default {
  setup() {
    const route = useRoute();
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth() + 1,
      d = date.getDate(),
      current = y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
    const state = reactive({
      start: current + " 00:00:00",
      showStartCalendar: false,
      end: current + " 23:59:59",
      showEndCalendar: false,
      datatype: 3,
      showPicker: false,
      datatypename: "小时数据",
      mindate: new Date(
        y -
          5 +
          "-" +
          (m < 10 ? "0" + m : m) +
          "-" +
          (d < 10 ? "0" + d : d) +
          " 00:00:00"
      )
    });
    // 数据类型
    const datatypeList = [
      { label: "分钟数据", value: 1 },
      { label: "5分钟数据", value: 2 },
      { label: "小时数据", value: 3 },
      { label: "日数据", value: 4 }
    ];
    const customFieldName = {
      text: "label",
      id: "value"
    };
    const onDataTypeConfirm = obj => {
      state.datatypename = obj.label;
      state.datatype = obj.value;
      state.showPicker = false;
    };
    // 开始日期
    const onStartConfirm = date => {
      state.start = `${date.getFullYear()}-${
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()} ${
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
      }:${
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
      }:${
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
      }`;
      state.showStartCalendar = false;
    };
    // 结束日期
    const onEndConfirm = date => {
      state.end = `${date.getFullYear()}-${
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}  ${
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
      }:${
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
      }:${
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
      }`;
      state.showEndCalendar = false;
    };
    // 折叠面板
    const activeNames = ref(["0"]);
    const dateactive = ref("date");
    const query = reactive({
      table: route.params.parentType,
      factortable: route.params.factortbale,
      queryId: route.params.queryId,
      factor_id: route.params.factortlist,
      outlet_id: route.params.outlet_id,
      tablenameOnlineManual: route.params.tablenameOnlineManual
    });
    const factorlist = ref([]);
    // 获取因子
    const getFactorList = () => {
      Http.get(
        {
          method: "list",
          table: "VIEW_MON_EPP_POINT_FACTOR",
          tablename_factor: query.factortable,
          outlet_id: query.outlet_id
        },
        false,
        true
      )
        .then(res => {
          if (res.map.length > 0) {
            factorlist.value = res.map;
            if (query.factor_id == 0) {
              query.factor_id = res.map[0].tempfactor_id.toString();
            }
            getlist();
          } else {
            getBarList([], [], []);
            getLineChart([], [], []);
          }
        })
        .catch(() => {});
    };
    const chartList = ref([]);
    const getlist = () => {
      Http.get(
        {
          method: "query",
          queryId: query.queryId,
          tablename_online_manual: query.tablenameOnlineManual,
          startDate: state.start,
          endDate: state.end,
          factor_id: query.factor_id,
          monitor_data_type: state.datatype,
          outlet_id: query.outlet_id
        },
        false,
        true
      )
        .then(res => {
          if (res.map.length > 0) {
            chartList.value = res.map;
            var baseData = res.map,
              obj = {},
              deList = [],
              yList = [],
              xList = [];
            for (var i = 0; i < baseData.length; i++) {
              xList.push(baseData[i].monitor_date);
              if (!obj[baseData[i].factor_name]) {
                var arr = [];
                arr.push(baseData[i]);
                obj[baseData[i].factor_name] = arr;
              } else {
                obj[baseData[i].factor_name].push(baseData[i]);
              }
            }
            deList.map(el => {
              xList.push(el);
            });
            xList = [...new Set(xList)];
            xList.forEach(() => {
              yList.push("0");
            });
            getChartJson(obj, xList, yList.join(","));
          } else {
            getBarList([], [], []);
            getLineChart([], [], []);
          }
        })
        .catch(() => {});
    };
    const getChartJson = (obj, xlist, ylist) => {
      var lenged = [];
      var lineserieslist = [],
        barserieslist = [];
      for (var key in obj) {
        var newYlist = ylist.split(",");
        let lineYaxis = {
          name: key,
          type: "line",
          data: [],
          yAxisIndex: key == "废气流量" ? 1 : 0
        };
        let barYaxis = {
          name: key,
          type: "bar",
          data: [],
          barWidth: "3",
          yAxisIndex: key == "废气流量" ? 1 : 0
        }; //,itemStyle:{normal:{color:'#F78840'}}
        lenged.push(key);
        var objList = obj[key];
        xlist.forEach((element, index) => {
          objList.forEach(items => {
            if (element == items.monitor_date) {
              newYlist[index] = items.monitor_value;
            }
          });
          barYaxis.data = newYlist;
          lineYaxis.data = newYlist;
        });
        lineserieslist.push(lineYaxis);
        barserieslist.push(barYaxis);
      }
      getBarList(lenged, xlist, barserieslist);
      getLineChart(lenged, xlist, lineserieslist);
    };
    // 生成柱状图
    const getBarList = (lenged, x, y) => {
      let bar_chart = $echarts.init(document.getElementById("barChart"));
      bar_chart.clear();
      bar_chart.setOption({
        grid: {
          left: "3%",
          right: "6%",
          bottom: "3%",
          top: "15%",
          containLabel: true
        },
        title: {
          text: x.length === 0 ? "暂无数据" : "",
          x: "center",
          y: "center",
          textStyle: {
            color: "#cccccc",
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "line" }
        },
        legend: {
          show: true,
          type: "scroll",
          itemGap: 5,
          itemWidth: 10,
          itemHeight: 10,
          icon: "circle",
          padding: 3,
          top: "2%",
          left: "center",
          data: lenged
        },
        xAxis: {
          splitLine: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            // rotate:60,
            textStyle: {
              color: "#000000" //坐标值得具体的颜色
            }
          },
          data: x
        },
        yAxis: [
          {
            splitLine: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              textStyle: {
                color: "#000000" //坐标值得具体的颜色
              }
            }
          },
          { show: false }
        ],
        series: y
      });
      window.onresize = function() {
        //自适应大小
        bar_chart.resize();
      };
    };
    // 生成折线图
    const getLineChart = (lenged, x, y) => {
      let line_chart = $echarts.init(document.getElementById("lineChart"));
      line_chart.clear();
      line_chart.setOption({
        grid: {
          left: "3%",
          right: "6%",
          bottom: "3%",
          top: "15%",
          containLabel: true
        },
        title: {
          text: x.length === 0 ? "暂无数据" : "",
          x: "center",
          y: "center",
          textStyle: {
            color: "#cccccc",
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "line" }
        },
        legend: {
          show: true,
          type: "scroll",
          itemGap: 5,
          itemWidth: 10,
          itemHeight: 10,
          icon: "circle",
          padding: 3,
          top: "2%",
          left: "center",
          data: lenged
        },
        xAxis: {
          splitLine: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            // rotate:60,
            textStyle: {
              color: "#000000" //坐标值得具体的颜色
            }
          },
          data: x
        },
        yAxis: [
          {
            splitLine: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              textStyle: {
                color: "#000000" //坐标值得具体的颜色
              }
            }
          },
          { show: false }
        ],
        series: y
      });
      window.onresize = function() {
        //自适应大小
        line_chart.resize();
      };
    };
    // deloradd
    const deloradd = factor => {
      var old_factor = query.factor_id,
        isHas = ("," + old_factor + ",").search("," + factor + ",") != -1,
        oldFactorList = old_factor.split(",");
      if (isHas) {
        var newFactorList = oldFactorList.filter(item => item != factor);
        oldFactorList = newFactorList;
      } else {
        oldFactorList.push(factor);
        oldFactorList = [...new Set(oldFactorList)];
      }
      query.factor_id = oldFactorList.length > 0 ? oldFactorList.join(",") : "";
    };
    const getmonDate = () => {
      var type = dateactive.value;
      const date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth() + 1,
        d = date.getDate(),
        current =
          y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
      if (type == "date") {
        state.start = current + " 00:00:00";
        state.end = current + " 23:59:59";
      } else if (type == "week") {
        state.start = GetDateStr(7) + " 00:00:00";
        state.end = current + " 23:59:59";
      } else if (type == "month") {
        date.setMonth(date.getMonth() - 1);
        var ly = date.getFullYear(),
          lm = date.getMonth() + 1,
          ld = date.getDate();
        state.start =
          ly +
          "-" +
          (lm < 10 ? "0" + lm : lm) +
          "-" +
          (ld < 10 ? "0" + ld : ld) +
          " 00:00:00";
        state.end = current + " 23:59:59";
      }
    };
    const GetDateStr = AddDayCount => {
      var now = new Date();
      now.setDate(now.getDate() - AddDayCount); //获取AddDayCount天后的日期
      var y = now.getFullYear();
      var m =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //获取当前月份的日期，不足10补0
      var d = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //获取当前几号，不足10补0
      // var hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时
      // var minu = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();//得到分钟
      // var sec = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds();//得到秒
      return y + "-" + m + "-" + d;
    };
    watch(
      () => [dateactive.value, query.factor_id],
      ([newval, oldval], [newfactor, oldfactor]) => {
        if (newval != oldval) {
          getmonDate();
        }
        if (newfactor != oldfactor) {
          if (query.factor_id != "" && state.start != "" && state.end != "") {
            getlist();
          }
        }
      }
    );
    watch(state, () => {
      if (query.factor_id != "" && state.start != "" && state.end != "") {
        getlist();
      }
    });
    onMounted(() => {
      getFactorList();
    });

    return {
      activeNames,
      state,
      onStartConfirm,
      onEndConfirm,
      customFieldName,
      onDataTypeConfirm,
      datatypeList,
      dateactive,
      query,
      factorlist,
      getFactorList,
      getlist,
      deloradd,
      getmonDate
    };
  }
};
</script>
